/**
*	Project: Milanesi Editore
*	Developer: Deniel Dinale
*	Version: 1.0.0
*	Date: 26/08/2022
**/

/** Reset **/
* {
	padding: 0;
	margin: 0;
	border: 0;
	background: none;
	list-style: none;
	outline: none;
	box-sizing: border-box;
}

:root {
	--bg: #FEFEFE;
	--bg-2: #F8F8F8;
	--title: #2D2D2D;
	--txt: #212529;
	--yellow: #fdc800;
	--blue: #1980b6;
	--cyan: #28a4c9;
	--green: #7ab342;
	--grey: #868e96;
	--light-grey: #dddcdc;
	--red: #c02942;
	--bc: rgba(0,0,0, .1);

	--ff: 'Open Sans', sans-serif;
	--fs: 1.02rem;
	
	--gap: 2rem;
	--shadow: .6rem rgba(0,0,0,.1);
	--ease-in: .4s ease-in;
}

body {
	font-family: var(--ff);
	font-display: swap;
	font-size: var(--fs);
	font-weight: 300;
	color: var(--txt);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--bg);
	letter-spacing: .063rem;
}

h1,
h2,
h3,
h4,
button {
	font-weight: 400;
	color: var(--title);
}

h1 {
	font-size: 3.6rem;
	font-weight: 300;
	margin-bottom: 2rem;
}
h2 {
	font-size: 2rem;
	margin-bottom: 1.6rem;
}
h3 {
	font-size: 1.4rem;
	margin-bottom: 1.5rem;
}
h4 {
	font-size: 1rem;
	margin-bottom: .5rem;
}
a {
	color: var(--title);
}
p,
ul {
	font-size: 1.05rem;
}
p:not(:last-child) {
	margin-bottom: 2rem;
}
b,
strong,
.bold {
	font-weight: 400;
}
img {
	width: 100%;
}

hr {
	border-bottom: .1rem solid var(--bc);
}

.form-border {
	padding: 3.7rem;
	margin-inline: 3.5rem;
	border: .1rem solid var(--bc);
}
input,
textarea,
select {
	width: 100%;
	font-family: var(--ff);
	font-size: var(--fs);
	font-weight: 400;
	padding: .6rem .8rem;
	margin-bottom: 2rem;
	background-color: var(--bg);
	border: .1rem solid var(--bc);
	color: var(--txt);
	transition: var(--ease-in);
}
input:hover,
textarea:hover,
input:focus,
textarea:focus {
	border-color: var(--blue);
}
input[type="submit"] {
	width: auto;
	padding-inline: 4rem;
	margin-top: 2rem;
}
input[type="checkbox"] {
	width: auto;
	margin-right: .5rem;
	margin-block: 2rem;
}
label {
	position: relative;
	display: block;
}
label > span {
	display: inline-block;
	margin-bottom: .6rem;
}
label > svg {
	position: absolute;
	width: 1.6rem;
	height: 1.6rem;
	top: 2.5rem;
	right: .6rem;
	padding-left: .6em;
	border-left: .1rem solid var(--bc);
	fill: var(--light-grey);
}

canvas {
	max-width: 25rem;
	max-height: 25rem;
}


/** Utilities **/
main {
	min-height: 65vh;
	padding-top: 5rem;
}
section { padding-block: 6rem; }

.container {
	max-width: 75em;
	padding-inline: 2.5rem;
	margin-inline: auto;
}

.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.place-center {
	place-items: center;
}
.place-start {
	place-items: start;
}
.space-between {
	justify-content: space-between;
}
.self-start {
	align-self: start;
}
.self-center {
	align-self: center;
}
.gtc-1-2 { grid-template-columns: .5fr 1fr; }
.gtc-2-1 { grid-template-columns: 1fr .5fr; }
.gtc-3-1 { grid-template-columns: 1fr .25fr; }
.gtc-3 { grid-template-columns: repeat(3, 1fr); }
.gtc-4 { grid-template-columns: repeat(4, 1fr); }
.gtc-5 { grid-template-columns: repeat(5, 1fr); }

.order {	order: -1; }
.span-2 { grid-column: span 2; }

.g-_5 { gap: .5rem; }
.g-1 { gap: 1rem; }
.g-2 { gap: var(--gap); }
.g-3 { gap: 3rem; }
.g-5 { gap: 5rem; }
.cg-2 { column-gap: var(--gap); }
.rg-10 {	row-gap: 10rem; }

.mx-w-15 { max-width: 15rem; }
.mx-w-25 { max-width: 25rem; }
.mx-w-30 { max-width: 30rem; }
.mx-w-35 { max-width: 35rem; }
.mx-w-40 { max-width: 40rem; }
.mx-w-50 { max-width: 50rem; }
.mx-h-20 { max-height: 20rem; width: auto; }

.m-auto { margin: auto; }
.mb-0 { margin-bottom: 0 !important; }
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem !important; }
.mb-3 { margin-bottom: 3rem !important; }
.mb-4 { margin-bottom: 4rem !important; }
.mb-5 { margin-bottom: 5rem !important; }
.ml-4 { margin-left: 4rem; }
.mt--1 { margin-top: -1rem; }

.p-0 { padding: 0 !important; }
.pb-0 { padding-bottom: 0 !important; }
.pl-4 { padding-left: 4rem; }
.pbk-xs { padding-block: 2rem !important; }
.pbk-sm { padding-block: 3.5rem !important; }
.pbk-lg { padding-block: 10rem !important; }

.bg-light { background-color: var(--bg-2) !important; }
.bg-blue { background-color: var(--blue) !important; }
.bg-yellow { background-color: var(--yellow) !important; }
.bg-grey { background-color: var(--grey) !important; }
.bg-red { background-color: var(--red) !important; }

.bt {	border-top: .1rem solid var(--bc); }
.bbk { border-block: .1rem solid var(--bc); }

.relative {
	position: relative;
}
.absolute-right {
	position: absolute;
	top: 1rem;
  	right: calc( var(--gap) * 2 );
}
.absolute-head {
	position: absolute;
	bottom: 0;
  	right: -15%;
}

.txt-success { color: var(--green); }
.txt-danger { color: var(--red); }
.txt-white { color: var(--bg) !important; }
.txt-center { text-align: center; }
.fw-300 {	font-weight: 300; }

.fill-danger { fill: var(--red); }
.fill-blue { fill: var(--blue); }
.fill-cyan { fill: var(--cyan); }
.fill-grey { fill: var(--grey); }
.fill-yellow { fill: var(--yellow); }
.fill-green { fill: var(--green); }

.icon-h-10 {
	width: auto;
	height: 10rem;
	margin-bottom: 1rem;
}

.list li,
.list-numb li {
	list-style: disc;
	padding-left: .6rem;
	margin-left: 1.8rem;
}
.list-numb li {
	list-style: decimal;
}
.list-check li {
	position: relative;
	padding-left: 2rem;
	margin-bottom: 1rem;
}
.list-check li:before {
	content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 18.6"><path d="m15.88,2.72c-1.76-1.76-4.09-2.72-6.58-2.72S4.48.97,2.72,2.72C.97,4.48,0,6.82,0,9.3s.97,4.82,2.72,6.58c1.76,1.76,4.09,2.72,6.58,2.72s4.82-.97,6.58-2.72c1.76-1.76,2.72-4.09,2.72-6.58s-.97-4.82-2.72-6.58Zm-6.58,15.28C4.5,18,.6,14.1.6,9.3S4.5.6,9.3.6s8.7,3.9,8.7,8.7-3.9,8.7-8.7,8.7Z"/><path d="m14.6,5.8c0-.08-.03-.16-.09-.21-.12-.12-.31-.12-.42,0l-6.79,6.79-2.79-2.79c-.06-.06-.14-.09-.21-.09s-.15.03-.21.09c-.06.06-.09.13-.09.21s.03.16.09.21l3,3c.11.11.31.11.42,0l7-7c.06-.06.09-.13.09-.21Z"/></svg>');
	display: block;
	position: absolute;
	top: .15rem;
	left: 0;
	width: 1.2rem;
	height: 1.2rem;
}

.btn {
	display: inline-block;
	padding: 1rem 2rem;
	border-radius: 2rem;
	font-size: var(--fs);
	text-decoration: none;
	background-color: var(--blue);
	color: var(--bg);
	transition: var(--ease-in);
}
.btn:hover {
	opacity: .7;
}
.btn-border {
	color: var(--blue);
	background: transparent;
	border: .12rem solid var(--blue);
}
.btn-border:hover {
	color: var(--bg);
	background: var(--blue);
}

.btn-feature {
	position: relative;
	width: max-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 1.5rem;
	border-radius: 3rem;
	padding: 0;
	padding-right: 2.5rem;
}
.btn-feature::before {
	content: '';
	position: absolute;
	top: 100%;
	left: 5%;
	width: 90%;
	height: .5rem;
	opacity: 0;
	background: radial-gradient(ellipse at center, rgba(0,0,0,.35) 0%, rgba(0,0,0,0) 80%);
	transition: var(--ease-in);
	z-index: -1;
}
.btn-feature div {
	display: grid;
	place-items: center;
	width: 4rem;
	height: 4rem;
	aspect-ratio: 1;
	padding: 1rem;
	border-radius: 100%;
	background-color: rgba(0,0,0,.1);
}
.btn-feature div svg {
	aspect-ratio: 1;
	fill: var(--bg);
}
.btn-feature:hover {
	transform: translateY(-.3rem);
	opacity: 1;
}
.btn-feature:hover::before {
	opacity: 1;
	transform: translateY(.3rem);
}
.btn-feature-border {
	color: var(--blue);
	border: .15rem solid var(--blue);
	background: transparent;
}
.btn-feature-border div {
	background: var(--blue);
}
.btn-feature-border-red {
	color: var(--red);
	border: .15rem solid var(--red);
	background: transparent;
}
.btn-feature-border-red div {
	background: var(--red);
}
.btn-feature-border-green {
	color: var(--green);
	border: .15rem solid var(--green);
	background: transparent;
}
.btn-feature-border-green div {
	background: var(--green);
}
.btn-icon {
	display: grid;
	place-items: center;
	aspect-ratio: 1;
	padding: 1rem;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: .1rem solid var(--blue);
}
.btn-icon svg {
	width: 1.8rem;
	fill: var(--blue);
}

.fixed-cta {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	padding-block: 1rem;
	background: linear-gradient( 90deg, var(--cyan), var(--blue) );
	transform: translateY(0);
	transition: var(--ease-in);
	z-index: 99;
}
.fixed-cta .flex,
.sm-flex-row {
	flex-direction: row !important;
	flex-wrap: nowrap;
}
.fixed-cta.visible {
	transform: translateY(100%);
}

.fadein {
	animation: fadeIn ease-out 1.5s;
	transition: var(--ease-in-out);
}
.visible { display: block !important; }
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
}


/** Header **/
header {
	position: fixed;
	width: 100%;
	display: grid;
	grid-template-columns: max-content 1fr;
	place-items: center;
	gap: var(--gap);
   padding: 1rem 3rem;
	font-size: var(--fs);
	background-color: var(--bg);
	box-shadow: .1rem .2rem var(--shadow);
   z-index: 98;
}
header a.logo {
	line-height: 0;
}
header a.logo img {
	position: relative;
	width: 10rem;
	height: 3.2rem;
	z-index: 9999;
}
header button.nav-toggle {
	display: none;
}
header a {
	text-decoration: none;
}
header nav#navbar {
	justify-self: end;
	color: var(--title);
}
header nav#navbar svg {
	width: 1.13rem;
	aspect-ratio: 1;
	transform: translateY(.1rem);
}
header nav#navbar > ul {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--gap);
}
header nav#navbar ul li.megamenu {
	cursor: pointer;
}
header nav#navbar ul li i {
	font-style: normal;
}
header nav#navbar ul li .btn {
	padding: .6rem 1rem;
}
header nav#navbar ul li.megamenu span::after,
header nav#navbar ul li span::after {
	content: '‹';
	margin-left: .5rem;
	display: inline-block;
	transform: rotate(-90deg);
	transition: var(--ease-in);
}
header nav#navbar ul li.megamenu:hover span::after {
	transform: rotate(90deg) translateY(-.3rem);
}
header nav#navbar ul li.megamenu > ul {
	position: fixed;
	inset: 0;
	top: 5.2rem;
	height: max-content;
	display: grid;
	place-content: start;
	gap: var(--gap);
	padding: var(--gap);
	padding-top: calc( var(--gap) - 1rem );
	background-color: var(--bg);
	border-top: .1rem solid var(--blue);
	box-shadow: .1rem .2rem var(--shadow);
	transform: rotateX(90deg);
	transform-origin: top;
	transition: var(--ease-in);
	cursor: default;
}

@media (min-width: 58.6em) {
	header nav#navbar ul li.megamenu:hover > ul {
		transform: rotateX(0);
	}
}

header nav#navbar ul li.megamenu > ul li {
	font-weight: 500;
}

header nav#navbar ul li.megamenu > ul li > ul,
header nav#navbar ul li > ul li > ul {
	margin-top: 1.6rem;
}

header nav#navbar ul li.megamenu > ul li > ul li {
	font-weight: 300;
}
header nav#navbar ul li.megamenu > ul li > ul li a,
header nav#navbar ul li > ul li > a {
	display: grid;
	grid-template-columns: 1.13rem 1fr;
	gap: .6rem;
	margin-top: .5rem;
}
header nav#navbar ul li.megamenu > ul li > ul li a:hover {
	opacity: .5;
}


/** Sections **/
section.hero {
	min-height: 55vh;
	background-position: bottom;
	background-size: contain;
}

section.head {
	padding-block: 4rem 0;
	text-align: center;
}
section.head.section-bg {
	padding-block: 12rem;
}
section.head h1 {
	font-size: 4.5rem;
	font-weight: 300;
	margin-bottom: 1rem;
}
section.head h1 svg {
	width: 5.5rem;
	aspect-ratio: 1;
	margin-right: 1rem;
	transform: translateY(1rem);
}

section.bg-light {
	border-block: .1rem solid var(--bc);
}
section.grid-translate {
	padding-block: 1rem;
}
section.grid-translate > *:nth-child(odd) {
	transform: translateY(-5rem);
}
section.grid-translate > *:nth-child(even) {
	transform: translateY(5rem);
}
.section-bg {
	background: var(--bg-url) no-repeat center;
	background-size: cover;
}

/** Faqs **/
.faq {
	padding: 1rem 1.2rem;
	margin-bottom: 1rem;
	border: .1rem solid var(--bc);
	transition: var(--trnst);
}
.faq h4 {
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
	gap: 1rem;
	margin: 0;
}
.faq button svg {
	width: 1.2rem;
	height: 1.2rem;
	fill: var(--blue);
}
.faq p {
	height: 0;
	transform: rotateX(90deg);
	transition: var(--trnst);
}
.faq p.active {
	padding-top: 1rem;
	height: auto;
	transform: rotateX(0);
}

/** Tabs **/
.tab {
	position: relative;
	padding-block: calc( var(--gap) / 2 );
	padding-right: var(--gap);
	margin-right: 3.5rem;
	border-bottom: .1rem solid var(--blue);
	transition: var(--trnst);
}
.tab * {
	transition: var(--trnst);
	transition-duration: 2s;
}
.tab h3 {
	margin-bottom: 0;
	cursor: pointer;
}
.tab svg {
	position: absolute;
	top: 0;
	right: -2.5rem;
	display: block;
	width: 4rem;
	fill: none;
	stroke: var(--blue);
	stroke-width: .15rem;
	visibility: collapse;
	opacity: 0;
}
.tab.active svg {
	visibility: visible;
	opacity: 1;
	height: auto;
}
.tab-triangle {
	padding-left: var(--gap);
}
.tab-triangle:before {
	content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>');
	display: block;
	position: absolute;
	left: 0;
	top: 30%;
	width: .75rem;
	height: .75rem;
}
.tab-image {
	position: relative;
}
.tab-image p {
	position: absolute;
	top: 0;
	left: 0;
	width: 24rem;
	padding: var(--gap);
	background-color: var(--bg-2);
	transition: var(--trnst);
}
.tab-image img {
	padding-left: calc( var(--gap) * 2 );
	aspect-ratio: 5/4;
	object-fit: cover;
	transition: var(--trnst);
}

/** Price tables **/
.price-table {
	border-radius: .2rem;
	border: .1rem solid var(--bc);
}
.price-title {
	font-size: 2.3rem;
	font-weight: 300;
	padding: 1rem;
	margin: 0;
	text-align: center;
	background-color: var(--light-grey);
	color: var(--bg);
}
.price-active {
	background-color: #c5fbc5;
	color: var(--title);
}

.price-table .price {
	min-height: 9rem;
	padding: 2rem 1rem;
	margin: 0;
	color: var(--txt);
	background-color: var(--bg-2);
}
.price-table h2.price {
	font-size: 3.75rem;
}
.price-table h2.price small,
.price-table h2.price sup {
	font-size: 1rem;
}
.price-table h2.price small:last-child {
	margin-left: -1.2rem;
}
.price-features {
	font-size: .9rem;
	padding: 1.5rem 1rem;
	padding-left: .3rem;
}
.price-features li:not(:last-child) {
	margin-bottom: 1rem;
}
.price-features li.title {
	font-size: 1rem;
	font-weight: 500;
	list-style: none;
	margin: 2rem 0;
}
.price-features li svg {
	width: 2rem;
	fill: var(--green);
}
.price-features.same-h-row li {
	height: 2.6rem;
	display: grid;
	place-content: center;
	margin: .4rem;
}
.price-features.same-h-row.start li {
	justify-content: start;
}


/** Popover **/
.popover-btn {
	position: relative;
	margin-left: .5rem;
	transform: translateY(.1rem);
}
.popover {
	position: absolute;
	display: block;
	bottom: 2rem;
	left: -7rem;
	width: 15rem;
	padding: .5rem;
	border-radius: .5rem;
	border: .1rem solid var(--bc);
	background-color: var(--bg);
	transform: rotateX(90deg);
	transform-origin: bottom;
	transition: var(--ease-in);
	z-index: 99;
}
.popover:before {
	content: '';
	position: absolute;
	bottom: .7rem;
	left: 6.7rem;
	display: block;
	width: .8rem;
	aspect-ratio: 1;
	border: .1rem solid var(--bc);
	border-left: 0;
	border-top: 0;
	background-color: var(--bg);
	transform: rotate(45deg) translateY(.7rem) translateX(1rem);
}
.popover[data-visible="true"] {
	transform: rotateX(0);
}
/* carousel */
.carousel {
	position: relative;
	padding-block: 5rem;
	overflow: hidden;
}
.carousel > article {
	display: none;
	transition: var(--trnst);
}
.carousel > article.active {
	display: grid;
}
.dot-controls {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 1rem;
	margin-top: 3rem;
}
.dot-controls > span {
	display: block;
	width: .8rem;
	height: .8rem;
	border-radius: 50%;
	background-color: var(--bc);
	cursor: pointer;
}
.dot-controls > span.active {
	background-color: var(--blue);
}


/** Custom **/
.bg-box {
	position: relative;
	padding: var(--gap) calc( var(--gap) * 2 );
	margin-bottom: var(--gap);
	border-radius: .4rem;
	background: linear-gradient( 90deg, var(--cyan), var(--blue) );
}
.bg-box * {
	color: var(--bg);
}

.line-logo {
	border-bottom: .1rem solid var(--bc);
	transform: translateY(4rem);
}
.line-logo svg {
	width: 4.5rem;
	padding: 1rem;
	background-color: var(--bg);
	transform: translateY(2.65rem);
}

.feature-box {
	padding: 2rem 1rem;
	text-align: center; 
}
.feature-box div.circle {
	width: max-content;
	aspect-ratio: 1;
	padding: 1.2rem;
	margin-inline: auto;
	margin-bottom: 1rem;
	border-radius: 50%;
	background-color: var(--bg);
	box-shadow: 0 .5rem 1rem 0 rgba(0,0,0, .06);
}
.feature-box div.circle svg {
	display: block;
	width: 2.5rem;
	aspect-ratio: 1;
}

.card-box {
	border: .1rem solid var(--bc);
}
.card-box h2 {
	padding: 1rem;
	margin: 0;
	background: var(--light-grey);
}
.card-box p {
	padding: 1rem;
}

.i-info {
	position: relative;
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border: 1px solid;
	border-radius: 50%
}
.i-info::after,
.i-info::before {
	content: "";
	display: block;
	position: absolute;
	border-radius: .2rem;
	width: 1px;
	background: var(--title);
	left: 7px
}
.i-info::after {
	bottom: 2px;
	height: 6px
}
.i-info::before {
	height: 2px;
	top: 2px
} 

button.gotop {
	display: none;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	width: 3rem;
	aspect-ratio: 1;
	opacity: .6;
	animation: fadeIn ease-out 1.5s;
	transition: var(--ease-in-out);
	z-index: 9999;
}
button.gotop:hover { opacity: 1; }

.box-line-grid,
.box-line-grid-2 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	text-align: center;
	row-gap: calc( var(--gap) * 2 );
}
.box-line-grid-2 {
	grid-template-columns: repeat(2, 1fr);
}
.box-line-grid > div,
.box-line-grid-2 > div {
	padding-inline: var(--gap);
	border-right: .1rem solid var(--bc);
}
.box-line-grid > div:nth-child(3n) {
	border: none;
}
.box-line-grid-2 > div:nth-child(2n) {
	border: none;
}


/** Footer **/
footer {
	padding-top: 4rem;
	background: var(--bg-2);
	border-top: .1rem solid var(--bc);
}
footer div {
	padding-inline: 3rem;
}

footer img {
	max-width: 10rem;
}

footer ul li {
	font-size: .9rem;
	margin-bottom: .5rem;
}
footer ul li h4,
footer ul li i,
footer ul li span {
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
	text-transform: uppercase;
	margin-bottom: 1.5rem;
}
footer ul li a {
	text-decoration: none;
}
footer svg {
	width: 1.13rem;
	aspect-ratio: 1;
	margin-right: .6rem;
	transform: translateY(.1rem);
}
footer div:last-child {
	background: var(--bc);
	padding: 1rem;
	margin-top: 4rem;
}


/** Responsive Mobile **/
@media (max-width: 36.5em) {
	.container {
		padding-inline: 1rem;
	}
}
@media (max-width: 1200px) {
	/** Header **/
	header {
		padding-inline: 1rem;
	}
	
	header button.nav-toggle {
		position: fixed;
		top: 1.8rem;
		right: 1.3rem;
		display: grid;
		gap: .6rem;
		width: 2rem;
		z-index: 999;
		transition: var(--ease-in);
		cursor: pointer;
	}
	header button.nav-toggle .bar1,
	header button.nav-toggle .bar2,
	header button.nav-toggle .bar3 {
		display: block;
		width: 100%;
		height: .12rem;
		background: var(--txt);
		transition: var(--ease-in);
		cursor: pointer;
	}
	header button.nav-toggle[aria-expanded="true"] {
		right: .6rem;
	}
	header button.nav-toggle[aria-expanded="true"] .bar1 {
		transform: rotate(-45deg) translateX(-50%);
	}
	header button.nav-toggle[aria-expanded="true"] .bar2 {
		opacity: 0;
	}
	header button.nav-toggle[aria-expanded="true"] .bar3 {
		transform: rotate(45deg) translateX(-50%);
	}
	header nav#navbar {
		position: fixed;
		inset: 0;
		padding: 6rem 0;
		background: var(--bg);
		box-shadow: -.2rem 0 var(--shadow);
		overflow-y: auto;
		z-index: 998;
		transform: translateX(100%);
		transition: transform var(--ease-in);
	}
	header nav#navbar[data-visible="true"] {
		transform: translateX(0);
	}
	header nav#navbar ul {
		font-size: 1.22rem;
	}
	header nav#navbar > ul {
		align-items: start;
		flex-direction: column;
		gap: 0;
	}
	header nav#navbar ul li {
		position: relative;
	}
	header nav#navbar > ul > li {
		width: 100%;
		padding-inline: 2rem;
		padding-block: .8rem;
		border-bottom: .1rem solid var(--bc);
	}
	header nav#navbar ul li[aria-expanded="true"] span::after,
	header nav#navbar ul li[aria-expanded="true"] > ul li[aria-expanded="true"] span::after {
		transform: rotate(90deg) translateY(-.3rem);	
	}
	header nav#navbar ul li[aria-expanded="true"] > ul li span::after {
		transform: rotate(-90deg);
	}
	header nav#navbar ul li > ul {
		position: relative;
		height: 0;
		grid-template-columns: 1fr;
		gap: .6rem;
		padding: 0;
		box-shadow: none;
	}
	header nav#navbar ul li > ul li > ul,
	header nav#navbar ul li > ul {
		display: none;
		height: 0;
		padding-inline: 1rem;
		transform: rotateX(90deg);
		transform-origin: top;
		transition: var(--ease-in);
	}
	header nav#navbar ul li > ul[data-visible="true"],
	header nav#navbar ul li > ul[data-visible="true"] li > ul[data-visible="true"] {
		display: block;
		height: max-content;
		padding: 1rem;
		transform: rotateX(0);
	}
	/** footer **/
	footer div.gtc-5 {
		grid-template-columns: repeat(3, 1fr);
		gap: calc( var(--gap) / 2 );
	}
	footer ul li > ul {
		position: relative;
		height: 0;
		display: none;
		grid-template-columns: 1fr;
		gap: .6rem;
		box-shadow: none;
		transform: rotateX(90deg);
		transform-origin: top;
		transition: var(--ease-in);
	}
	footer ul li > ul[data-visible="true"] {
		display: block;
		height: max-content;
		padding-bottom: .8rem;
		transform: rotateX(0);
	}
	footer ul li span {
		margin-bottom: 0;
		cursor: pointer;
	}
	footer ul li span::after {
		content: '‹';
		margin-left: .5rem;
		display: inline-block;
		transform: rotate(-90deg);
		transition: var(--ease-in);
	}
	footer ul li[aria-expanded="true"] span::after {
		transform: rotate(90deg) translateY(-.3rem);	
		margin-bottom: 1.5rem;
	}
}

@media (max-width: 44em) {
	h1 {
		font-size: 2.6rem;
	}
	section.head h1 {
		font-size: 3rem;
	}

	.form-border {
		padding: 2rem;
		margin-inline: 0;
	}

	.rg-10 {
		row-gap: 6rem;
	}
	
	.grid,
	.gtc-1-2,
	.gtc-2-1,
	.gtc-3,
	.gtc-4,
	.gtc-5 {
		grid-template-columns: 1fr;
	}
	.flex {
		flex-direction: column;
	}
	.pl-4 {
		padding-left: 2rem;
	}
	.pt-sm-4 { padding-top: 4rem !important; }
	.pb-sm-0 { padding-bottom: 0; }
	.sm-none {
		display: none;
	}
	
	.box-line-grid,
	.box-line-grid-2 {
		grid-template-columns: 1fr;
	}
	.box-line-grid > div,
	.box-line-grid-2 > div {
		border-right: none;
	}
	.bg-box {
		padding-inline: 2rem;
	}
	.tab {
		padding-right: 0;
		margin-right: 0;
	}
	.tab p {
		visibility: collapse;
		opacity: 0;
		height: 0;
	}
	.tab.active h3 {
		margin-bottom: calc( var(--gap) / 2 );
	}
	.tab.active p {
		visibility: visible;
		opacity: 1;
		height: auto;
	}
	.tab svg,
	.tab.active svg {
		display: none;
	}

	footer div {
		padding-inline: 1rem;
	}
	footer div.gtc-5 {
		grid-template-columns: 1fr;
	}
	footer .gtc-5 > ul > li {
		padding-bottom: 1rem;
		margin-bottom: 0;
		border-bottom: .1rem solid var(--bc);
	}
}


/** Animation **/
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}